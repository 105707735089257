h3.success {
  font-size: 1.75em;
  color: green;
}

h3.fail {
  font-size: 1.75em;
  color: red;
}

span.success {
  margin-bottom: 0.4em;
  color: green;
}

span.fail {
  margin-bottom: 0.4em;
  color: red;
}
